import React from 'react';
import styled from 'styled-components';
import {graphql} from 'gatsby';
import {GatsbyImage} from "gatsby-plugin-image";
import SocialMenu from '../components/SocialMenu';
import ContactFormCom from '../components/ContactFormCom';
import SEO from '../components/SEO';

const StyledWrapper = styled.div`
  position: relative;
  height: 60vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  margin-top: 100px;
  color: white;
  text-align: center;
`;

const StyledContact = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9995;
  padding: 20px 50px;

  ${({theme}) => theme.mq.desktop} {
    padding: 100px 200px;
  }
`;

const ContactFormIntro = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 40px auto;
  font-size: ${({theme}) => theme.font.size.xs};

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.s};
  }
`;

const StyledContactTop = styled.div`
  font-size: ${({theme}) => theme.font.size.xxs};
  margin-bottom: 4em;

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.xs};
  }
`;

const StyledContactData = styled.div`
  font-weight: bold;
  line-height: 0.8;
  padding-bottom: 2rem;
  font-size: ${({theme}) => theme.font.size.xs};

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.s};
  }
`;

const ContactLink = styled.a`
  color: white;
  text-decoration: none;
`;

const StyledContactTopHeader = styled.h2`
  font-size: ${({theme}) => theme.font.size.s};

  ${({theme}) => theme.mq.desktop} {
    font-size: ${({theme}) => theme.font.size.m};
  }
`;

const StyledImage = styled(GatsbyImage)`
  position: relative;
  height: 60vh;
  width: 100%;
  opacity: 0.8;
  filter: brightness(0.5);
`;

export default function ContactPage({data}) {
    const contactImageHero = data.contact.edges[0];

    return (
        <>
            <SEO title="Kontakt"/>
            <StyledWrapper>
                <StyledContact className="contactHero">
                    <StyledContactTop>
                        <StyledContactData>PRACOWNIA FLORYSTYCZNA</StyledContactData>
                        <StyledContactTopHeader className="highlight">
                            Lawendowy Kącik
                        </StyledContactTopHeader>
                    </StyledContactTop>
                    <StyledContactData>
                        <p>Renata Weber</p>
                        <p>ul. Miodowa 6</p>
                        <p>62-200 Gniezno</p>
                        <br/>
                        <ContactLink href="tel:661248082">+48 661 248 082</ContactLink>
                        <br/>
                        <br/>
                        <ContactLink href="mailto:kwiaciarnia.lawendowykacik@gmail.com">
                            kwiaciarnia.lawendowykacik@gmail.com
                        </ContactLink>
                    </StyledContactData>
                    <SocialMenu/>
                </StyledContact>
                <StyledImage image={contactImageHero.node.image.asset.gatsbyImageData}/>
            </StyledWrapper>
            <ContactFormIntro>
                <h2>Masz pytania?</h2>
                <span>
          Marzysz o niebanalnych dekoracjach wykonanych z precyzją oraz
          najnowszymi trendami? Napisz do nas już dziś!{' '}
        </span>
            </ContactFormIntro>
            <ContactFormCom/>
            <iframe
                title="google-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38839.51612592542!2d17.56819869935238!3d52.52493440391004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4704915cab1da8d1%3A0xf9fe68f916b446f3!2sPRACOWNIA%20FLORYSTYCZNA%20LAWENDOWY%20K%C4%84CIK!5e0!3m2!1spl!2spl!4v1606773576864!5m2!1spl!2spl"
                width="100%"
                height="450"
                frameBorder="0"
                allowFullScreen="true"
                aria-hidden="false"
            />
        </>
    );
}

export const query = graphql`
  query SinglePageContactQuery {
    contact: allSanityContact {
      edges {
        node {
          id
          image {
            asset {
                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
